import { template } from "@ember/template-compiler";
import Base, { type BaseArgs, type BaseSignature } from './base';
import { action } from '@ember/object';
import FormCheckbox, { type FormCheckboxArgs } from '@frontile/forms/components/form-checkbox';
export interface ChangesetFormFieldsCheckboxArgs extends BaseArgs, FormCheckboxArgs {
    _groupName?: string;
    _parentOnChange?: (value: unknown, event: Event) => void;
    onChange: (value: boolean, event: Event) => void;
}
export interface ChangesetFormFieldsCheckboxSignature extends BaseSignature {
    Args: ChangesetFormFieldsCheckboxArgs;
    Element: HTMLInputElement;
    Blocks: {
        default: [];
    };
}
export default class ChangesetFormFieldsCheckbox extends Base<ChangesetFormFieldsCheckboxSignature> {
    get fullFieldName(): string {
        return this.args._groupName ? `${this.args._groupName}.${this.args.fieldName}` : this.args.fieldName;
    }
    get value(): boolean {
        return this.args.changeset.get(this.fullFieldName);
    }
    get errors(): string[] {
        if (typeof this.args.errors !== 'undefined') {
            if (typeof this.args.errors === 'string') {
                return [
                    this.args.errors
                ];
            }
            return this.args.errors;
        }
        const fieldErrors1 = this.args.changeset.errors.filter((error1)=>{
            return error1.key === this.fullFieldName;
        });
        return fieldErrors1.reduce((errors1: string[], error1): string[] =>{
            if (Array.isArray(error1.validation)) {
                const results1 = [
                    ...errors1
                ];
                error1.validation.forEach((err1)=>{
                    results1.push(...err1);
                });
                return results1;
            } else {
                return [
                    ...errors1,
                    error1.validation
                ];
            }
        }, []);
    }
    @action
    async validate(): Promise<void> {
        await this.args.changeset.validate(this.fullFieldName);
    }
    @action
    async handleChange(value1: boolean, event1: Event): Promise<void> {
        this.args.changeset.set(this.fullFieldName, value1);
        await this.validate();
        if (typeof this.args._parentOnChange === 'function') {
            this.args._parentOnChange(value1, event1);
        }
        if (typeof this.args.onChange === 'function') {
            this.args.onChange(value1, event1);
        }
    }
    static{
        template(`
    <FormCheckbox
      @onChange={{this.handleChange}}
      @checked={{this.value}}
      @hint={{@hint}}
      @name={{@name}}
      @containerClass={{@containerClass}}
      @size={{@size}}
      ...attributes
    >
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{@label}}
      {{/if}}
    </FormCheckbox>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
