import { endOfDay, startOfDay } from 'date-fns';

export default function quarterRange(year, quarter) {
  if (quarter < 1 || quarter > 4) {
    throw new Error('Quarter must be between 1 and 4');
  }

  const startMonths = [1, 4, 7, 10];
  const endMonths = [3, 6, 9, 12];

  const startMonth = startMonths[quarter - 1];
  const endMonth = endMonths[quarter - 1];

  const startDate = startOfDay(new Date(year, startMonth - 1, 1));
  const endDate = endOfDay(new Date(year, endMonth, 0)); // Last day of the month

  return [startDate, endDate];
}
