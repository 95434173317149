import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { DrawerArgs } from '../drawer';
import useFrontileClass from '@frontile/core/helpers/use-frontile-class';
export interface DrawerFooterArgs extends Pick<DrawerArgs, 'placement'> {
    size: string;
}
export interface DrawerFooterSignature {
    Args: DrawerFooterArgs;
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
export default class DrawerFooter extends Component<DrawerFooterSignature> {
    static{
        template(`
    <div
      class={{useFrontileClass "drawer" @placement @size part="footer"}}
      ...attributes
    >
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
