export default function secondsToTime(duration) {
  if (duration < 0) {
    duration = duration * -1;
  }

  var seconds = parseInt(duration % 60),
    minutes = parseInt((duration / 60) % 60),
    hours = parseInt((duration / (60 * 60)) % 24);

  return {
    hours,
    minutes,
    seconds,
  };
}