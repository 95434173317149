import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
export interface VisuallyHiddenSignature {
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
export default class VisuallyHidden extends Component<VisuallyHiddenSignature> {
    static{
        template(`
    <div class="sr-only" ...attributes>
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
