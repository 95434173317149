import { template } from "@ember/template-compiler";
import Base, { type BaseArgs, type BaseSignature } from './base';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import FormRadio, { type FormRadioArgs } from '@frontile/forms/components/form-radio';
export interface ChangesetFormFieldsRadioArgs extends BaseArgs, FormRadioArgs {
    onChange: (value: unknown, event: Event) => void;
}
export interface ChangesetFormFieldsRadioSignature extends BaseSignature {
    Args: ChangesetFormFieldsRadioArgs;
    Element: HTMLInputElement;
    Blocks: {
        default: [];
    };
}
export default class ChangesetFormFieldsRadio extends Base<ChangesetFormFieldsRadioSignature> {
    @action
    async handleChange(value1: unknown, event1: Event): Promise<void> {
        event1.preventDefault();
        this.args.changeset.set(this.args.fieldName, value1);
        await this.validate();
        if (typeof this.args.onChange === 'function') {
            this.args.onChange(value1, event1);
        }
    }
    static{
        template(`
    <FormRadio
      @onChange={{this.handleChange}}
      @checked={{this.value}}
      @hint={{@hint}}
      @value={{@value}}
      @name={{@name}}
      @containerClass={{@containerClass}}
      @size={{@size}}
      ...attributes
      {{on "blur" this.validate}}
    >
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{@label}}
      {{/if}}
    </FormRadio>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
