import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import ChangesetFormFieldsCheckbox from './checkbox';
import FormCheckboxGroup, { type FormCheckboxGroupArgs } from '@frontile/forms/components/form-checkbox-group';
import type { BaseArgs, BaseSignature } from './base';
import type { BufferedChangeset } from 'ember-changeset/types';
import type { WithBoundArgs } from '@glint/template';
export interface ChangesetFormFieldsGroupArgs extends BaseArgs, FormCheckboxGroupArgs {
    errors?: string[];
    changeset: BufferedChangeset;
    groupName?: string;
    onChange?: (value: unknown, event: Event) => void;
}
export interface ChangesetFormFieldsCheckboxGroupSignature extends BaseSignature {
    Args: ChangesetFormFieldsGroupArgs;
    Blocks: {
        default: [checkbox: WithBoundArgs<typeof ChangesetFormFieldsCheckbox, '_groupName'> & WithBoundArgs<typeof ChangesetFormFieldsCheckbox, '_parentOnChange'> & WithBoundArgs<typeof ChangesetFormFieldsCheckbox, 'changeset'> & WithBoundArgs<typeof ChangesetFormFieldsCheckbox, 'size'>];
    };
    Element: HTMLDivElement;
}
export default class ChangesetFormFieldsCheckboxGroup extends Component<ChangesetFormFieldsCheckboxGroupSignature> {
    constructor(owner1: unknown, args1: ChangesetFormFieldsGroupArgs){
        super(owner1, args1);
        assert('<ChangesetForm> fields must receive @changeset', typeof this.args.changeset !== 'undefined');
    }
    get errors(): string[] {
        if (typeof this.args.errors !== 'undefined') {
            return this.args.errors;
        }
        if (!this.args.groupName) {
            return [];
        }
        const fieldErrors1 = this.args.changeset.errors.filter((error1)=>{
            return error1.key === this.args.groupName;
        });
        return fieldErrors1.reduce((errors1: string[], error1): string[] =>{
            if (Array.isArray(error1.validation)) {
                const results1 = [
                    ...errors1
                ];
                error1.validation.forEach((err1)=>{
                    results1.push(...err1);
                });
                return results1;
            } else {
                return [
                    ...errors1,
                    error1.validation
                ];
            }
        }, []);
    }
    @action
    async validate(): Promise<void> {
        if (this.args.groupName) {
            await this.args.changeset.validate(this.args.groupName);
        }
    }
    @action
    handleChange(value1: unknown, event1: Event): void {
        this.validate();
        if (typeof this.args.onChange === 'function') {
            this.args.onChange(value1, event1);
        }
    }
    static{
        template(`
    <FormCheckboxGroup
      @onChange={{this.handleChange}}
      @errors={{this.errors}}
      @label={{@label}}
      @hint={{@hint}}
      @hasError={{@hasError}}
      @showError={{@showError}}
      @hasSubmitted={{@hasSubmitted}}
      @containerClass={{@containerClass}}
      @size={{@size}}
      @isInline={{@isInline}}
      ...attributes
      as |_ api|
    >
      {{yield
        (component
          ChangesetFormFieldsCheckbox
          _groupName=@groupName
          _parentOnChange=api.onChange
          changeset=@changeset
          size=@size
        )
      }}
    </FormCheckboxGroup>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
