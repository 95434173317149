import { helper } from '@ember/component/helper';

export function durationToSeconds(value, named = {}) {
  let hms = value.match(/[hHmMsS]/);
  let colon = value.match(/:/);

  let smallestUnit = named?.smallestUnit || 'seconds';

  // let minDuration = named?.minDuration;
  // let maxDuration = named?.maxDuration;

  if (colon) {
    return detectColonValue(value, { smallestUnit });
  } else if (hms) {
    return detectHMSValue(value, { smallestUnit });
  } else {
    if (parseInt(value, 10) > 0) {
      if (value.length <= 2) {
        return detectHMSValue(value + 's');
      } else if (value.length <= 4) {
        return detectColonValue(value.slice(-4, -2) + ':' + value.slice(-2));
      }
    } else {
      return false;
    }
  }
}

function detectColonValue(value, options = {}) {
  let values = value.split(':');
  let seconds = 0;
  let minutes = 0;
  let hours = 0;
  let s = values.pop();
  let m = values.pop();
  let h = values.pop();

  if (h && m?.length == 1) {
    minutes = parseInt(m, 10) * 10;
  } else if (m?.length > 0) {
    minutes = parseInt(m, 10);
  }
  if (m && s?.length == 1) {
    seconds = parseInt(s, 10) * 10;
  } else if (s?.length > 0) {
    seconds = parseInt(s, 10);
  }

  if (h) {
    hours = parseInt(h, 10);
  }

  return seconds + minutes * 60 + hours * 60 * 60;
}

function detectHMSValue(value, options = {}) {
  let values = value.match(
    /([0-9]+\s?[hH])?\s*([0-9]+\s?[mM])?\s*([0-9]+\s?[sS])?/,
  );

  let hours = parseInt(values[1] || 0, 10);
  let minutes = parseInt(values[2] || 0, 10);
  let seconds = parseInt(values[3] || 0, 10);

  return seconds + minutes * 60 + hours * 60 * 60;
}

export default helper(durationToSeconds);
