import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { hash } from '@ember/helper';
export interface ButtonArgs {
    /**
   * The HTML type of the button
   *
   * @defaultValue 'button'
   */ type?: 'button' | 'submit' | 'reset';
    /**
   * The button appearance
   *
   * @defaultValue 'default'
   */ appearance?: 'default' | 'outlined' | 'minimal' | 'custom';
    /**
   * The intent of the button
   */ intent?: 'primary' | 'success' | 'warning' | 'danger';
    /**
   * The size of the button
   */ size?: 'xs' | 'sm' | 'lg' | 'xl';
    /**
   * Disable rendering the button element. It yields an object with classNames instead.
   */ isRenderless?: boolean;
}
export interface ButtonSignature {
    Args: ButtonArgs;
    Blocks: {
        default: [{
                classNames: string;
            }];
    };
    Element: HTMLButtonElement;
}
export default class Button extends Component<ButtonSignature> {
    get type(): string {
        if (this.args.type) {
            return this.args.type;
        }
        return 'button';
    }
    get classNames(): string {
        const names1 = [];
        if (this.args.appearance === 'outlined') {
            names1.push('btn-outlined');
        } else if (this.args.appearance === 'minimal') {
            names1.push('btn-minimal');
        } else if (this.args.appearance === 'custom') {
            names1.push('btn-custom');
        } else {
            names1.push('btn');
        }
        if (this.args.size) {
            names1.push(`${names1[0]}--${this.args.size}`);
        }
        if (this.args.intent) {
            names1.push(`${names1[0]}--${this.args.intent}`);
        }
        return names1.join(' ');
    }
    static{
        template(`
    {{#if @isRenderless}}
      {{yield (hash classNames=this.classNames)}}
    {{else}}
      <button type={{this.type}} class={{this.classNames}} ...attributes>
        {{yield (hash classNames=this.classNames)}}
      </button>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
