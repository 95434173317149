import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { assert } from '@ember/debug';
import { next } from '@ember/runloop';
import { hash, fn } from '@ember/helper';
import { on } from '@ember/modifier';
import ChangesetFormFieldsInput from './fields/input';
import ChangesetFormFieldsTextarea from './fields/textarea';
import ChangesetFormFieldsSelect from './fields/select';
import ChangesetFormFieldsCheckbox from './fields/checkbox';
import ChangesetFormFieldsCheckboxGroup from './fields/checkbox-group';
import ChangesetFormFieldsRadio from './fields/radio';
import ChangesetFormFieldsRadioGroup from './fields/radio-group';
import type { BufferedChangeset } from 'ember-changeset/types';
import type { WithBoundArgs } from '@glint/template';
export interface ChangesetFormArgs {
    /** Changeset Object */ changeset: BufferedChangeset;
    /**
   * Run Changeset execute method instead of save
   * @defaultValue false
   * */ runExecuteInsteadOfSave?: boolean;
    /**
   * Always show errors if there are any
   * @defaultValue false
   */ alwaysShowErrors?: boolean;
    /**
   * Validate the changeset on initialization
   * @defaultValue false
   */ validateOnInit?: boolean;
    /** Callback executed when from `onsubmit` event is triggered */ onSubmit?: (data: unknown, event: Event) => void;
    /** Callback executed when from `onreset` event is triggered */ onReset?: (data: unknown, event: Event) => void;
}
export interface ChangesetFormSignature {
    Args: ChangesetFormArgs;
    Blocks: {
        default: [{
                Input: WithBoundArgs<typeof ChangesetFormFieldsInput, 'changeset'> & WithBoundArgs<typeof ChangesetFormFieldsInput, 'hasSubmitted'> & WithBoundArgs<typeof ChangesetFormFieldsInput, 'showError'>;
                Textarea: WithBoundArgs<typeof ChangesetFormFieldsTextarea, 'changeset'> & WithBoundArgs<typeof ChangesetFormFieldsTextarea, 'hasSubmitted'> & WithBoundArgs<typeof ChangesetFormFieldsTextarea, 'showError'>;
                Select: WithBoundArgs<typeof ChangesetFormFieldsSelect, 'changeset'> & WithBoundArgs<typeof ChangesetFormFieldsSelect, 'hasSubmitted'> & WithBoundArgs<typeof ChangesetFormFieldsSelect, 'showError'>;
                Checkbox: WithBoundArgs<typeof ChangesetFormFieldsCheckbox, 'changeset'>;
                CheckboxGroup: WithBoundArgs<typeof ChangesetFormFieldsCheckboxGroup, 'changeset'> & WithBoundArgs<typeof ChangesetFormFieldsCheckboxGroup, 'hasSubmitted'> & WithBoundArgs<typeof ChangesetFormFieldsCheckboxGroup, 'showError'>;
                Radio: WithBoundArgs<typeof ChangesetFormFieldsRadio, 'changeset'>;
                RadioGroup: WithBoundArgs<typeof ChangesetFormFieldsRadioGroup, 'changeset'> & WithBoundArgs<typeof ChangesetFormFieldsRadioGroup, 'hasSubmitted'> & WithBoundArgs<typeof ChangesetFormFieldsRadioGroup, 'showError'>;
                state: {
                    hasSubmitted: boolean;
                };
            }];
    };
    Element: HTMLFormElement;
}
export default class ChangesetForm extends Component<ChangesetFormSignature> {
    @tracked
    hasSubmitted = false;
    constructor(owner1: unknown, args1: ChangesetFormArgs){
        super(owner1, args1);
        assert('@changeset must be defined on <ChangesetForm> component', this.args.changeset);
        if (this.args.validateOnInit) {
            next(()=>{
                this.args.changeset.validate();
            });
        }
    }
    @action
    async handleSubmit(changeset1: BufferedChangeset, event1: Event): Promise<void> {
        event1.preventDefault();
        await changeset1.validate();
        this.hasSubmitted = true;
        if (changeset1.isInvalid) {
            return;
        }
        let result1;
        if (this.args.runExecuteInsteadOfSave) {
            result1 = changeset1.execute();
        } else {
            result1 = await changeset1.save({});
        }
        if (typeof this.args.onSubmit === 'function') {
            this.args.onSubmit(result1.data, event1);
        }
    }
    @action
    handleReset(changeset1: BufferedChangeset, event1: Event): void {
        event1.preventDefault();
        this.hasSubmitted = false;
        const { data: data1 } = changeset1.rollback();
        if (typeof this.args.onReset === 'function') {
            this.args.onReset(data1, event1);
        }
    }
    static{
        template(`
    <form
      ...attributes
      {{on "submit" (fn this.handleSubmit @changeset)}}
      {{on "reset" (fn this.handleReset @changeset)}}
    >

      {{yield
        (hash
          Input=(component
            ChangesetFormFieldsInput
            changeset=@changeset
            hasSubmitted=this.hasSubmitted
            showError=@alwaysShowErrors
          )
          Textarea=(component
            ChangesetFormFieldsTextarea
            changeset=@changeset
            hasSubmitted=this.hasSubmitted
            showError=@alwaysShowErrors
          )
          Select=(component
            ChangesetFormFieldsSelect
            changeset=@changeset
            hasSubmitted=this.hasSubmitted
            showError=@alwaysShowErrors
          )
          Checkbox=(component
            ChangesetFormFieldsCheckbox
            changeset=@changeset
            hasSubmitted=this.hasSubmitted
            showError=@alwaysShowErrors
          )
          CheckboxGroup=(component
            ChangesetFormFieldsCheckboxGroup
            changeset=@changeset
            hasSubmitted=this.hasSubmitted
            showError=@alwaysShowErrors
          )
          Radio=(component
            ChangesetFormFieldsRadio
            changeset=@changeset
            hasSubmitted=this.hasSubmitted
            showError=@alwaysShowErrors
          )
          RadioGroup=(component
            ChangesetFormFieldsRadioGroup
            changeset=@changeset
            hasSubmitted=this.hasSubmitted
            showError=@alwaysShowErrors
          )
          state=(hash hasSubmitted=this.hasSubmitted)
        )
      }}

    </form>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
