import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { buildWaiter } from '@ember/test-waiters';
import { on } from '@ember/modifier';
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import safeStyles from '../../utils/safe-styles';
const waiter = buildWaiter('@frontile/core:collapsible');
export interface CollapsibleArgs {
    /**
   * If true, the content will be visible
   */ isOpen: boolean;
    /**
   * The height for the content in it's collapsed state.
   * The unit of the value should be included, eg. '10px'.
   *
   * @defaultValue 0
   */ initialHeight?: string;
}
export interface CollapsibleSignature {
    Args: CollapsibleArgs;
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
export default class Collapsible extends Component<CollapsibleSignature> {
    isInitiallyOpen = false;
    waiterToken?: unknown;
    isCurrentlyOpen = false;
    constructor(owner1: unknown, args1: CollapsibleArgs){
        super(owner1, args1);
        if (this.args.isOpen) {
            this.isInitiallyOpen = true;
            this.isCurrentlyOpen = true;
        }
    }
    get styles(): ReturnType<typeof safeStyles> {
        let styles1: Record<string, string | number> = {};
        if (!this.isInitiallyOpen) {
            styles1 = {
                ...styles1,
                height: this.args.initialHeight || 0,
                opacity: this.args.initialHeight ? '1' : '0'
            };
        }
        if (this.args.initialHeight || !this.isInitiallyOpen) {
            styles1 = {
                ...styles1,
                overflow: 'hidden'
            };
        }
        return safeStyles(styles1);
    }
    @action
    update(element1: HTMLElement, [isOpen1]: boolean[]): void {
        if (this.isCurrentlyOpen !== !!isOpen1) {
            this.waiterToken = waiter.beginAsync();
        }
        if (isOpen1) {
            this.expand(element1);
        } else {
            this.contract(element1);
        }
    }
    @action
    onTransitionEnd(event1: TransitionEvent): void {
        if ((event1.propertyName === 'height' || event1.propertyName == 'opacity') && this.args.isOpen) {
            (event1.target as HTMLElement).style.height = 'auto';
            (event1.target as HTMLElement).style.overflow = '';
        }
        if (this.waiterToken) {
            // when is opened, wait for height transition to finish
            // when is opened, wait for opacity transition to finish at 1
            // when closed, wait for opacity transition to finish at 0
            // when closed and has initialHeight, wait for height transition to finish
            if ((this.args.isOpen && event1.propertyName === 'height') || (!this.args.isOpen && event1.propertyName === 'opacity' && (event1.target as HTMLElement).style.opacity == '0') || (this.args.isOpen && event1.propertyName === 'opacity' && (event1.target as HTMLElement).style.opacity == '1') || (!this.args.isOpen && this.args.initialHeight && event1.propertyName === 'height')) {
                waiter.endAsync(this.waiterToken);
            }
        }
    }
    heightTransition(duration1: number): string {
        return `height ${duration1}s cubic-bezier(0.4, 0, 0.2, 1) 0ms`;
    }
    opacityTransition(duration1: number): string {
        return `opacity ${duration1}s ease-in-out 0ms`;
    }
    expand(element1: HTMLElement): void {
        this.isCurrentlyOpen = true;
        element1.style.transition = [
            this.heightTransition(0.4),
            this.opacityTransition(0.3)
        ].join(', ');
        element1.style.overflow = 'hidden';
        const height1 = element1.scrollHeight;
        window.requestAnimationFrame(()=>{
            element1.style.opacity = '1';
            element1.style.height = `${height1}px`;
        });
    }
    contract(element1: HTMLElement): void {
        this.isCurrentlyOpen = false;
        const height1 = element1.scrollHeight;
        element1.style.transition = '';
        element1.style.overflow = 'hidden';
        window.requestAnimationFrame(()=>{
            element1.style.height = `${height1}px`;
            element1.style.transition = [
                this.heightTransition(0.2),
                this.opacityTransition(0.3)
            ].join(', ');
            window.requestAnimationFrame(()=>{
                if (!this.args.initialHeight) {
                    element1.style.opacity = '0';
                }
                element1.style.height = this.args.initialHeight || '0';
            });
        });
    }
    static{
        template(`
    <div
      style={{this.styles}}
      ...attributes
      {{didUpdate this.update @isOpen}}
      {{on "transitionend" this.onTransitionEnd}}
    >
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
